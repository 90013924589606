<template>
  <el-cascader
    filterable
    placeholder="请选择服务项目"
    :props="contractProps"
    :options="options"
    v-model.array="id"
    @change="changeValue"
    size="small"
    style="width: 182px;margin:0"
    :disabled="edit"
  >
    <template #default="{ node, data }">
      <span
        v-if="data.categoryName == '+新增服务项目'"
        style="color:var(--themeColor,#17a2b8)"
        >{{ data.categoryName }}</span
      >
      <span
        v-else-if="data.categoryName == '业务类型管理'"
        style="color:var(--themeColor,#17a2b8)"
        @click="toBusiness"
        >{{ data.categoryName }}</span
      >
      <span v-else> {{ data.categoryName }} </span>
    </template>
  </el-cascader>

  <addBusinessTypeCrm
    @success="addSuccess"
    ref="addBusinessSuccess"
  ></addBusinessTypeCrm>
  <el-drawer title="业务类型" v-model="visible" destroy-on-close size="60%">
    <businessType ref="businessTypeCC" />
  </el-drawer>
</template>

<script>
import { categoryListNew } from "@/api/crm";
import addBusinessTypeCrm from "./addBusinessTypeCrm.vue";
import businessType from "../crmSettimg/businessType.vue";
export default {
  name: "selectBusiness",
  components: {
    addBusinessTypeCrm,
    businessType,
  },
  props: {
    category: Array,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      contractProps: {
        expandTrigger: "hover",
        label: "categoryName",
        value: "id",
      },
      bid: "",
      addId: "",
      visible: false,
    };
  },
  computed: {
    id: {
      get() {
        if (!this.category) {
          return "";
        }
        return this.category;
      },
      set(val) {
        if (val[0] == "addType") {
        } else if (val[1] == "addCategory") {
          this.bid = val[0] * 1;
          this.addId = val[0] * 1;
          let data = {
            typeId: this.addId,
          };
          this.$refs.addBusinessSuccess.init(data);
        } else {
          let arr = [];
          this.options.map((v) => {
            v.children.map((e) => {
              if (e.id == val[1] * 1) {
                arr.push(e);
              }
            });
          });
          this.$emit("update:category", val[1] * 1);
          this.$emit("success", val[1] * 1);
          this.$emit("successArr", arr);
        }
      },
    },
  },
  mounted() {
    this.initBus();
    this.getCategory();
  },
  methods: {
    initBus() {
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getCategory();
      });
    },
    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.options = res.data.data.list ? res.data.data.list : [];
          // this.options.push({
          //   categoryName: "+新增业务类型",
          //   id: "+新增业务类型",
          //   children: [
          //     {
          //       categoryName: "+新增服务项目",
          //       id: "addCategory",
          //     },
          //   ],
          // });
          this.options.map((v) => {
            v.children.unshift({
              categoryName: "+新增服务项目",
              id: "addCategory",
            });
          });
          //console.log(this.options);
          // this.options.push({
          //   categoryName: "业务类型管理",
          //   id: "addType",
          //   children: [],
          // });
        }
      });
    },
    addSuccess(row) {
      this.getCategory();
      this.id = [row.typeId, row.id];
      this.$emit('addBussiness')
    },
    toBusiness() {
      this.visible = true;
      //this.$refs.businessTypeCC.init();
    },
  },
};
</script>

<style scoped lang="less"></style>
