<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="addCategoryDialog"
    title="新增"
    width="400px"
  >
    <el-form
      :model="categoryForm"
      ref="categoryFormRef"
      :rules="rules"
      status-icon
      size="small"
    >
      <el-form-item label="业务类型：" prop="typeId">
        <el-select
          size="small"
          v-model="categoryForm.typeId"
          placeholder="请选择业务类型"
          style="width: 200px;margin: 0;"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.categoryType"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务项目：" prop="categoryName">
        <el-input v-model="categoryForm.categoryName" placeholder="请输入服务项目" style="width: 200px;" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addCategoryDialog = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="createData()">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { categoryTypeList, saveCategoryNew } from "@/api/crm";
export default {
  name: "addBusinessTypeCrm",
  data() {
    return {
      addCategoryDialog: false,
      categoryForm: {
        typeId: null,
        categoryName: "",
      },
      rules: {
        typeId: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: "change",
          },
        ],
        categoryName: [
          {
            required: true,
            message: "请输入业务名称",
            trigger: "blur",
          },
        ],
      },
      options: [],
    };
  },

  mounted() {},

  methods: {
    init(row) {
      this.getList();
      this.categoryForm.typeId = row.typeId
      this.addCategoryDialog = true;
    },
    getList() {
      categoryTypeList({}).then((res) => {
        this.options = res.data.data.list;
      });
    },
    createData() {
      this.$refs["categoryFormRef"].validate((valid) => {
        if (valid) {
          saveCategoryNew(this.categoryForm)
            .then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("新增成功");
                res.data.data.info.typeId = this.categoryForm.typeId
                //console.log(1);
                this.$emit("success", res.data.data.info);
                this.addCategoryDialog = false;
                this.$bus.emit('businessTypeUpdate')
              }
            })
            .catch((err) => {
              //console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 30px;
}
:deep(.el-input__wrapper) {
  --el-input-border-color: #b9b9b9 !important;
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color))
    inset !important;
}
</style>
